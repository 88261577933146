import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../../../layouts/mdx';
import CodeFlowSnippet from '../../../snippets/oauth2-code-flow.mdx';
import ProductionSnippet from '../../../snippets/set-up-production.mdx';
export const _frontmatter = {
  "product": "verify",
  "category": "Integrations",
  "sort": 0,
  "title": "ASP.NET Core 6.0",
  "subtitle": "Accept MitID, Swedish BankID, Norwegian BankID and other eID logins with ASP.NET Core 6.0 and Criipto Verify"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This tutorial demonstrates how to integrate Criipto Verify into a fresh ASP.NET Core 6.0 application created with `}<inlineCode parentName="p">{`dotnet new mvc`}</inlineCode>{`.
The principles should apply to any ASP.NET Core 6.0 application.`}</p>
    <p>{`You can download a `}<a parentName="p" {...{
        "href": "https://github.com/criipto/samples/tree/master/Verify/dotnet-core-v6.x"
      }}>{`sample application from GitHub`}</a></p>
    <h2>{`Register Your Application in Criipto Verify`}</h2>
    <p>{`After you signed up for Criipto Verify, you must `}<a parentName="p" {...{
        "href": "https://dashboard.criipto.com/applications/add"
      }}>{`register an application`}</a>{` before you can actually try logging in with any eID.`}</p>
    <p>{`Once you register your application you will also need some of the information for communicating with Criipto Verify. You get these details from the settings of the application in the dashboard.`}</p>
    <p>{`Specifically you need the following information to configure you application`}</p>
    <ul>
      <li parentName="ul"><em parentName="li">{`Client ID`}</em>{` to identify you application to Criipto Verify.`}</li>
      <li parentName="ul"><em parentName="li">{`Domain`}</em>{` on which you will be communicating with Criipto Verify.`}</li>
      <li parentName="ul"><em parentName="li">{`Client Secret`}</em>{` to perform code exchange.`}</li>
    </ul>
    <h3>{`Register callback URLs`}</h3>
    <p>{`Before you can start sending authentication requests to Criipto Verify you need to register the URLs on which you want to receive the returned `}<em parentName="p">{`JSON Web Token`}</em>{`, JWT.`}</p>
    <p>{`The Callback URL of your application is the URL where Criipto Verify will redirect to after the user has authenticated in order for the OpenID Connect ASP.NET middleware to complete the authentication process.`}</p>
    <p>{`You will need to add these URLs to the list of allowed URLs for your application:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`https://localhost:5001/callback
http://localhost:5000/callback
https://localhost:5001/signout
http://localhost:5000/signout
`}</code></pre>
    <p>{`If you deploy your application to a different URL you will also need to ensure to add that URL to the Callback URLs. `}</p>
    <h2>{`Configure the OAuth2 code flow`}</h2>

    <CodeFlowSnippet mdxType="CodeFlowSnippet" />
    <h2>{`Install dependencies`}</h2>
    <p>{`To integrate Criipto Verify with ASP.NET Core you will use the Cookie and OpenID Connect (OIDC) authentication handlers. `}<inlineCode parentName="p">{`Microsoft.AspNetCore.Authentication.Cookies`}</inlineCode>{` is usually included but `}<inlineCode parentName="p">{`Microsoft.AspNetCore.Authentication.OpenIdConnect`}</inlineCode>{` will need to be installed:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`dotnet add package Microsoft.AspNetCore.Authentication.OpenIdConnect
`}</code></pre>
    <h3>{`Configure OpenID Connect Middleware`}</h3>
    <p>{`To enable authentication in your ASP.NET Core application, use the OpenID Connect (OIDC) middleware.`}</p>
    <p>{`To add the authentication services, call the `}<inlineCode parentName="p">{`AddAuthentication`}</inlineCode>{` method. To enable cookie authentication, call the `}<inlineCode parentName="p">{`AddCookie`}</inlineCode>{` method.`}</p>
    <p>{`Next, configure the OIDC authentication handler. Add a call to `}<inlineCode parentName="p">{`AddOpenIdConnect`}</inlineCode>{`. Configure the necessary parameters, such as `}<inlineCode parentName="p">{`ClientId`}</inlineCode>{`, `}<inlineCode parentName="p">{`ClientSecret`}</inlineCode>{`, `}<inlineCode parentName="p">{`ResponseType`}</inlineCode>{`, and not least the `}<inlineCode parentName="p">{`Authority`}</inlineCode>{`. The latter is used by the middleware to get the metadata describing the relevant endpoints, the signing keys etc.`}</p>
    <p>{`The OIDC middleware requests both the `}<inlineCode parentName="p">{`openid`}</inlineCode>{` and `}<inlineCode parentName="p">{`profile`}</inlineCode>{` scopes by default, you may configure additional scopes if your application is `}<a parentName="p" {...{
        "href": "https://docs.criipto.com/verify/getting-started/oidc-intro/#the-scope-parameter"
      }}>{`configured with dynamic scopes`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`// appsettings.json
{
  "Criipto": {
    "Domain": "{{YOUR_CRIIPTO_DOMAIN}}",
    "ClientId": "{{YOUR_CLIENT_ID}}",
    "ClientSecret": "YOUR_CLIENT_SECRET"
  }
}
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-csharp"
      }}>{`// Program.cs

builder.Services.Configure<CookiePolicyOptions>(options =>
{
    // This lambda determines whether user consent for non-essential cookies is needed for a given request.
    options.CheckConsentNeeded = context => true;
    options.MinimumSameSitePolicy = SameSiteMode.None;
});

builder.Services.AddAuthentication(options => {
    options.DefaultScheme = CookieAuthenticationDefaults.AuthenticationScheme;
    options.DefaultChallengeScheme = OpenIdConnectDefaults.AuthenticationScheme;
})
.AddCookie()
.AddOpenIdConnect(options => {
    options.ClientId = builder.Configuration["Criipto:ClientId"];
    options.ClientSecret = builder.Configuration["Criipto:ClientSecret"];
    options.Authority = $"https://{builder.Configuration["Criipto:Domain"]}/";
    options.ResponseType = "code";

    // The next to settings must match the Callback URLs in Criipto Verify
    options.CallbackPath = new PathString("/callback"); 
    options.SignedOutCallbackPath = new PathString("/signout");
});
`}</code></pre>
    <p>{`You can have a look at our `}<a parentName="p" {...{
        "href": "https://github.com/criipto/samples/blob/master/Verify/dotnet-core-v6.x/Program.cs"
      }}>{`sample Program.cs`}</a>{` to see how it fits together with the rest of Program.cs`}</p>
    <h3>{`Enable the OpenID Connect middleware`}</h3>
    <p>{`To enable the OIDC middleware you must configure your application to use authentication and authorization:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-csharp"
      }}>{`// Program.cs

app.UseAuthentication();
app.UseAuthorization();

`}</code></pre>
    <p>{`You can have a look at our `}<a parentName="p" {...{
        "href": "https://github.com/criipto/samples/blob/master/Verify/dotnet-core-v6.x/Program.cs"
      }}>{`sample Program.cs`}</a>{` to see how it fits together with the rest of Program.cs`}</p>
    <h2>{`Trigger Login and Logout in Your Application`}</h2>
    <p>{`After the middleware for performing the authentication is wired up, the next step is to perform the actual authentication.`}</p>
    <h3>{`Protected resources trigger login`}</h3>
    <p>{`One way to trigger the authentication flow is to tag routes in ASP.NET MVC with the `}<inlineCode parentName="p">{`Authorize`}</inlineCode>{`. This is a way of telling the framework to only allow requests from authenticated users.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-csharp"
      }}>{`[Authorize] // If not already authenticated, this kicks off the process
public IActionResult Protected()
{
    return View();
}
`}</code></pre>
    <p>{`Note that you may plug in your own Authorization handlers derived from `}<inlineCode parentName="p">{`Microsoft.AspNetCore.Authorization.AuthorizationHandler<TRequirement>`}</inlineCode>{` to add additional guards beyond just authentication.`}</p>
    <h3>{`Explicit logout`}</h3>
    <p>{`Logout requires both terminating the local session by removing the cookies as well as telling Criipto Verify that the session is over.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-csharp"
      }}>{`public async Task Logout()
{
    // Call the server to terminate the session
    await HttpContext.SignOutAsync(OpenIdConnectDefaults.AuthenticationScheme);

    // Remove authnetication cookies
    await HttpContext.SignOutAsync(CookieAuthenticationDefaults.AuthenticationScheme);
}
`}</code></pre>
    <h2>{`The runtime flow`}</h2>
    <p>{`In summary, the steps above will lead to a runtime flow looks like this:`}</p>
    <ol>
      <li parentName="ol">{`The web server starts the application which configures and initializes the OpenID Connect middleware. The middleware is configured with a URL from which it retrieves the metadata describing the various endpoints and encryption keys, such as the token and userinfo endpoints as well the token signing certificates`}</li>
      <li parentName="ol">{`A request for a resource protected by the `}<inlineCode parentName="li">{`[Authorization]`}</inlineCode>{` kicks off the OIDC middleware login flow`}</li>
      <li parentName="ol">{`The user's browser is redirected to the Criipto Verify service where actual login happens`}</li>
      <li parentName="ol">{`A callback with an issued `}<em parentName="li">{`authorization code`}</em>{` is sent back to the application and intercepted by the OIDC middleware`}</li>
      <li parentName="ol">{`The middleware calls the Criipto Verify service to exchange the code for an `}<em parentName="li">{`access token`}</em>{`. Note that this is a direct server to server call which - unlike the other communication - does not pass through the browser`}</li>
      <li parentName="ol">{`The access token is used by the OIDC middleware to retrieve the available user information which is set as claims on the user principal.`}</li>
    </ol>
    <p>{`If you want to inspect what is actually going on you may see much of it if you use for example chrome and turn on the developer tools to inspect the network traffic.`}</p>
    <h2>{`Setting up for Production`}</h2>

    <ProductionSnippet mdxType="ProductionSnippet" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      